import { ReactNode } from "react";
import styled from "styled-components";
import Loading from "../shared/Loading";

const Button = styled.button<{ fontSize: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background: var(--color-primary);
  color: #fff;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  font-size: ${({ fontSize }) => fontSize + "rem"};
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  white-space: nowrap;

  &:disabled {
    background: var(--color-gray-4);
    cursor: default;
  }

  &:hover:not(:disabled) {
    backface-visibility: hidden;
    transform: scale(1.02);
  }
`;

export default function ButtonPrimary({
  caption,
  action,
  disabled,
  size,
  type = "button",
  height = 12,
  isLoading = false,
  children,
  ...props
}: {
  caption?: string;
  type?: "button" | "submit" | "reset";
  size?: number;
  action?: () => void;
  disabled?: boolean;
  height?: number;
  isLoading?: boolean;
  children?: ReactNode;
}) {
  return (
    <Button
      onClick={() => {
        if (!type || type === "submit") return;
        if (disabled) return;
        action();
      }}
      type={type}
      fontSize={size ?? 0.75}
      disabled={disabled}
      {...props}
    >
      {isLoading && <Loading height={height} />}
      {!isLoading && (!!caption ? caption : children ?? "Button Caption")}
    </Button>
  );
}
