import styled from "styled-components";
import { useApolloClient } from "@apollo/client";
import { FETCH_EXTERNAL_PROFILE } from "../../../gql/external/fetchExternalProfile";
import { getWebURLFromBunchesURL } from "../../../utilities";
import { useRouter } from "next/router";
import MediaItem from "../../shared/MediaItem";
import MessageComponent from "../../chat/message/MessageComponent";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";

const ExternalProfileCollectionListItemButton = styled.a`
  border-radius: 8px;
  color: var(--color-gray-2);
  display: flex;
  cursor: pointer;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100%;
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:hover {
    background-color: rgba(0,0,255,0.02);
    transform: translate(-2px, 2px);
  }

  @media (min-width: ${MEDIA_QUERY_MD}) {
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
`;

const ExternalProfileCollectionListItemMessageWrapper = styled.div`
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ExternalProfileCollectionListItemInfoWrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: left;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 91.666667%; // 11/12
`;

const ExternalProfileCollectionListItemName = styled.h4`
  overflow: hidden;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const ExternalProfileCollectionListItemSubtitle = styled.span`
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-word;
`;

function ExternalProfileCollectionListItem({ item, ...props }) {
  const router = useRouter();
  const client = useApolloClient();
  const { pushTrackEvent } = useAnalyticsContext();

  const goToBunchSlug = (bunchId) => {
    if (!client) return;
    client.query({ query: FETCH_EXTERNAL_PROFILE, fetchPolicy: 'no-cache', variables: { id: bunchId } }).then((result) => {
      if (
        !result &&
        !result.data &&
        !result.data.fetchExternalProfile &&
        !result.data.fetchExternalProfile.object &&
        !result.data.fetchExternalProfile.object.slug
      )
        return;
      router.push(`$${result.data.fetchExternalProfile.object.slug}`);
    });
  };

  return (
    <ExternalProfileCollectionListItemButton
      onClick={() => {
        let actionUrl = getWebURLFromBunchesURL(item.targetUrl);

        pushTrackEvent("[External] Clicked Collection List Item", {
          actionUrl: actionUrl,
          name: item.name,
        });

        if (actionUrl.includes("bunch-id:")) {
          let bid = actionUrl.replace("bunch-id:", "");
          goToBunchSlug(bid);
        } else {
          router.push(actionUrl);
        }
      }}
      {...props}
    >
      {item.message && (<ExternalProfileCollectionListItemMessageWrapper>
          <MessageComponent display isExternal message={item.message}/>
        </ExternalProfileCollectionListItemMessageWrapper>)}
      {!item.message && <><MediaItem
        isSquare={true}
        minHeight={80}
        minWidth={80}
        fallback="/img/default_cover.png"
        itemUrl={item.imageUrl}
        alt={`${item.name} Media`}
      />
      <ExternalProfileCollectionListItemInfoWrapper>
        <ExternalProfileCollectionListItemName>{item.name}</ExternalProfileCollectionListItemName>
        {item.subtitle && (
          <ExternalProfileCollectionListItemSubtitle>{item.subtitle}</ExternalProfileCollectionListItemSubtitle>
        )}
      </ExternalProfileCollectionListItemInfoWrapper></>}
    </ExternalProfileCollectionListItemButton>
  );
}

export default ExternalProfileCollectionListItem;
