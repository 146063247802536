import ShareQRCode from "../../shared/ShareQRCode";
import styled from "styled-components";
import { MEDIA_QUERY_SM } from "../../../utilities/constants/mediaQueries";
import { BUNCHES_SHARE_CODE, SHARE_URL } from "../../../utilities/constants/urls";
import { User } from "../../../types";
import { isMobile } from "react-device-detect";
import { useAuthContext } from "../../state/AuthProvider";
import { useEffect, useState } from "react";
import { useRightSidebarContext } from "../../state/RightSidebar";
import { useRouter } from "next/router";

const Container = styled.div``;

const Button = styled.button<{ inverse?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin: 0.25rem 0;
  border-radius: 9999px;
  font-weight: 400;
  font-size: 1rem;
  height: 2.25rem;
  min-width: 10rem;
  color: ${({ inverse }) => (inverse ? "var(--color-gray-1)" : "var(--color-background)")};
  background: ${({ inverse }) => (inverse ? "var(--color-background)" : "var(--color-primary)")};
  border: 2px solid var(--color-gray-1);
  box-shadow:
    4px 4px 0px ${({ inverse }) => (inverse ? "#16B900" : "var(--color-gray-1)")},
    0px 0px 12px rgba(0, 0, 0, 0.05);
  transform: translate(0, 0);
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:hover {
    box-shadow:
      6px 6px 0px ${({ inverse }) => (inverse ? "#16B900" : "var(--color-gray-1)")},
      0px 0px 12px rgba(0, 0, 0, 0.05);
    transform: translate(-2px, -2px);
  }

  &:active {
    box-shadow:
      0px 0px 0px #313131,
      0px 0px 0px rgba(0, 0, 0, 0.05);
    transform: translate(4px, 4px);
  }

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 1rem;
    height: 2.25rem;
    margin: 0;
    min-width: 10rem;
  }
`;

function ExternalFriendButton({ user, className }: { user: User; className?: string }) {
  const router = useRouter();
  const { currentUser } = useAuthContext();
  const { closePanel } = useRightSidebarContext();
  const [isViewingSelf, setIsViewingSelf] = useState(currentUser?.userId === user.userId);
  const [isOpen, setIsOpen] = useState(false);
  const [sharerCode, setSharerCode] = useState(router.query?.sharer ?? BUNCHES_SHARE_CODE);

  const editSelfAction = () => {
    closePanel();
    router.push(`/edit-profile`);
  };

  useEffect(() => {
    setSharerCode(router.query?.sharer ?? BUNCHES_SHARE_CODE);
  }, [router.query]);

  useEffect(() => {
    setIsViewingSelf(currentUser?.userId === user?.userId);
  }, [user, currentUser]);

  if (!user) {
    return null;
  }

  return (
    <Container className={className}>
      <ShareQRCode
        caption={`Scan to message ${user.username}`}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shareCode={sharerCode + "/" + user.shareCode}
        userId={user.userId}
      />
      {!isViewingSelf && (
        <Button
          inverse
          onClick={() => {
            if (isMobile) {
              router.push(SHARE_URL + sharerCode + "/" + user.shareCode);
            } else {
              setIsOpen(true);
            }
          }}
        >
          Message {user.username}
        </Button>
      )}
      {isViewingSelf && (
        <Button inverse onClick={editSelfAction}>
          Edit Profile
        </Button>
      )}
    </Container>
  );
}

export default ExternalFriendButton;
