import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useQuery } from "@apollo/client";
import { createClient } from "../../utilities/trellis/apolloClient";
import { FETCH_EXTERNAL_PROFILE } from "../../gql/external/fetchExternalProfile";
import ExternalLayout from "../../components/external/layout";
import Loading from "../../components/shared/Loading";
import ExternalBunchProfile from "../../components/external/ExternalBunchProfile";
import ExternalUserProfile from "../../components/external/ExternalUserProfile";
import NoExternalProfile from "../../components/external/NoExternalProfile";
import { handleCaughtError } from "../../utilities";

export async function getServerSideProps({ params }) {
  const { term } = params;

  try {
    const { client } = createClient();
    const { data } = await client.query({
      query: FETCH_EXTERNAL_PROFILE,
      fetchPolicy: 'cache-first',
      variables: { id: term, term }
    });

    return {
      props: {
        initialProfileData: data?.fetchExternalProfile || null
      }
    };
  } catch (error) {
    return {
      props: {
        initialProfileData: null
      }
    };
  }
}

function ExternalProfile({ initialProfileData }) {
  const router = useRouter();
  const { term } = router.query;

  const { data, loading } = useQuery(FETCH_EXTERNAL_PROFILE, {
    variables: { id: term, term },
    skip: !term,
    initialData: initialProfileData ? { fetchExternalProfile: initialProfileData } : undefined
  });

  const profileData = data?.fetchExternalProfile;
  const profileObject = profileData?.object;
  const profileType = profileData?.type;

  if (loading || !profileType || !profileObject) {
    return (
      <ExternalLayout>
        <Loading />
      </ExternalLayout>
    );
  }

  return (
    <ExternalLayout>
      {profileType === "notFound" && <NoExternalProfile term={term} />}
      {profileType === "bunch" && <ExternalBunchProfile bunch={profileObject} />}
      {profileType === "user" && <ExternalUserProfile userProfile={profileObject} />}
    </ExternalLayout>
  );
}

export default ExternalProfile;
