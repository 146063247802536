import ImageWithFallback from "../../shared/ImageWithFallback";
import styled from "styled-components";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";
import { UserDetailsFragment } from "../../../gql/types";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import { useRouter } from "next/router";

const Button = styled.button<{ size: number }>`
  height: ${({ size }) => (size ? size + "px" : "120px")};
  position: relative;
  flex: 1 0 auto;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    width: ${({ size }) => (size ? size + "px" : "120px")};
    border-right: 2px solid var(--color-gray-1);
  }
`;

const Avatar = styled(ImageWithFallback)<{ size: number }>`
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  height: ${({ size }) => (size ? size + "px" : "120px")};

  @media (min-width: ${MEDIA_QUERY_MD}) {
    width: ${({ size }) => (size ? size + "px" : "120px")};
  }
`;

export default function ExternalUserAvatar({
  user,
  size = 40,
  className,
}: {
  user: UserDetailsFragment;
  isReadOnly?: boolean;
  action?: () => void;
  size?: number;
  className?: string;
}) {
  const router = useRouter();
  const { pushTrackEvent } = useAnalyticsContext();

  if (!user) {
    return null;
  }

  return (
    <Button
      size={size}
      className={className}
      onClick={() => {
        pushTrackEvent("[External] Clicked User Icon", {
          userId: user.userId,
          username: user.username,
        });

        router.push(`${user.username}`);
      }}
    >
      <Avatar
        priority
        src={user?.profilePictureUrl}
        fallbackSrc="/img/default_profile.png"
        alt={user?.username + "'s Profile Picture"}
        size={size}
        height={size}
        width={size}
        title={user?.username}
      />
    </Button>
  );
}
