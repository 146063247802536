import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { MessageDetailsFragment } from "../../gql/types";
import { createGenericContext } from "../../utilities/context";
import { useAuthContext } from "./AuthProvider";
import { useMessagesContext } from "./MessagesProvider";

type UseChat = {
  reactingTo: MessageDetailsFragment | null;
  setReactingTo: Dispatch<SetStateAction<MessageDetailsFragment | null>>;
  handleEmojiClick: (emoji: any) => void;
};

const [useChatContext, ChatContextProvider] = createGenericContext<UseChat>();

const useChat = (): UseChat => {
  const [reactingTo, setReactingTo] = useState<MessageDetailsFragment | null>(null);
  const { currentUser } = useAuthContext();
  const { addReaction } = useMessagesContext();

  const handleEmojiClick = (emoji: any) => {
    if (!currentUser || !reactingTo) return;

    const reactions = [
      ...(reactingTo?.reactions || []),
      {
        content: emoji.native,
        users: [currentUser.userId],
        count: 1,
      },
    ];

    const variables = {
      content: emoji.native,
      messageId: reactingTo.messageId,
      userId: currentUser?.userId,
    };

    const optimistic = {
      messageId: reactingTo.messageId,
      userId: reactingTo.userId,
      insertedAt: reactingTo.insertedAt,
      reactions,
      type: reactingTo.type,
      shareCode: reactingTo.shareCode,
      clientMessageId: reactingTo.clientMessageId,
      bunchId: reactingTo.bunchId,
      replyCount: reactingTo.replyCount,
      parentMessageId: reactingTo.parentMessageId,
      user: reactingTo.user,
      content: reactingTo.content,
      isModerated: reactingTo.isModerated,
      updatedAt: reactingTo.updatedAt,
      isBoosted: reactingTo.isBoosted,
      isPinned: reactingTo.isPinned,
      pinnedBy: reactingTo.pinnedBy,
      boostedUsers: reactingTo.boostedUsers,
    };

    addReaction(variables, optimistic);

    setReactingTo(null);
  };

  return {
    reactingTo,
    setReactingTo,
    handleEmojiClick,
  };
};

interface Props {
  children: ReactNode;
}

const ChatProvider = ({ children }: Props) => {
  const auth = useChat();

  return <ChatContextProvider value={auth}>{children}</ChatContextProvider>;
};

export { useChatContext, ChatProvider };
