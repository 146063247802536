import { ReactNode } from "react";
import Image from "next/image";
import styled from "styled-components";
import { MEDIA_QUERY_SM } from "../../../utilities/constants/mediaQueries";
import { SOCIAL_ASSETS } from "../../../utilities/constants/socialAssets";

const Button = styled.a<{ network?: string }>`
  cursor: pointer;
  flex: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  font-family: var(--font-family-headline);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.75rem;
  background: var(--color-background);
  color: var(--color-gray-1);
  padding: 0.5em;
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:hover {
    box-shadow: 4px -4px 0px ${({ inverse }) => (inverse ? "var(--color-primary)" : "var(--color-gray-1)")};
    transform: translate(-4px, 4px);
  }

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 1rem;
  }

  &:hover {
    background: ${({ network }) =>
      network ? (SOCIAL_ASSETS[network] ? SOCIAL_ASSETS[network].background : "") : "var(--color-primary)"};
    color: ${({ network }) =>
      network ? (SOCIAL_ASSETS[network] ? SOCIAL_ASSETS[network].text : "") : "var(--color-gray-1)"};
  }
`;

const SocialImage = styled(Image)`
  margin-right: 0.5rem;
`;

export default function SocialButton({
  children,
  url,
  network = false,
}: {
  children: ReactNode;
  url: string;
  network?: string;
}) {
  if (!SOCIAL_ASSETS[network]) return;
  if (!SOCIAL_ASSETS[network].show) return;

  return (
    <Button href={url} network={network} alt={network} target="_blank">
      <SocialImage src={SOCIAL_ASSETS[network] ? SOCIAL_ASSETS[network].image : ""} alt="" width="24" height="24" />
      {network}
      {children}
    </Button>
  );
}
