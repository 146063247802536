import MessageContent from "../../chat/message/MessageContent";
import Subtext from "../../shared/Subtext";
import UserAvatar from "../../chat/message/UserAvatar";
import Username from "../../shared/user/Username";
import styled from "styled-components";
import { MessageDetailsFragment, UserDetailsFragment } from "../../../gql/types";

const Container = styled.div<{ isExternal: boolean }>`
  cursor: default;
  display: flex;
  flex-direction: column;
  margin: 0.75rem auto;
  padding: 1rem;
  padding-bottom: 1.25rem;
  width: 100%;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--color-gray-1);
  background: var(--color-gray-7);
  border: 2px solid var(--color-gray-1);
  box-shadow:
    4px 4px 0px var(--color-gray-1),
    0px 0px 12px rgba(0, 0, 0, 0.05);
`;

const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 0.5rem;
`;

const MessageInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfo = styled.p`
  color: var(--color-gray-2);
  display: flex;
  font-size: 0.75rem;
  line-height: 1.4;
  margin: 0;
`;

const StyledUsername = styled(Username)`
  font-size: 0.9rem;
  font-weight: 600;

  > span {
    color: var(--color-gray-1);
  }
`;

const HighlightAction = styled.span`
  font-size: 0.9rem;
`;

const Avatar = styled(UserAvatar)`
  flex: 0 1 auto;
`;

function ExternalHighlightItem({
  isExternal,
  message,
}: {
  isExternal: boolean;
  message: MessageDetailsFragment & { pinnedDateDisplayText: string };
}) {
  const getAction = () => {
    if (!message) return;
    if (message.isPinned)
      return message.pinnedBy?.userId === message.user.userId ? (
        <>pinned their message</>
      ) : (
        <>
          pinned <StyledUsername isExternal={false} user={message.user as UserDetailsFragment} />
          &apos;s message
        </>
      );
    if (message.content.media.length > 0 && message.content.media[0].url.includes(".gif")) return "shared a GIF";
    if (message.content.media.length > 0) return "shared an image";
    if (message.content.urls.length > 0) return "shared a link";
    return "sent a highlighted message.";
  };

  return (
    <Container isExternal={isExternal}>
      <DetailsContainer>
        { /* @TODO Migrate to Boosted Users */ }
        <Details>
          <Avatar user={message.pinnedBy || message.user} />
          <MessageInfo>
            <UserInfo>
              <StyledUsername
                isExternal={false}
                user={
                  message.isPinned && message.pinnedBy
                    ? (message.pinnedBy as UserDetailsFragment)
                    : (message.user as UserDetailsFragment)
                }
              />
              <HighlightAction>&nbsp;{getAction()}</HighlightAction>
            </UserInfo>
            {!isExternal && message.pinnedDateDisplayText && <Subtext>{message.pinnedDateDisplayText}</Subtext>}
          </MessageInfo>
        </Details>
      </DetailsContainer>
      <MessageContent message={message} inline={false} isModerated={message.isModerated} />
    </Container>
  );
}

export default ExternalHighlightItem;
