import Image from "next/image";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.5rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const FriendCount = styled.span`
  font-weight: 600;
`;

const FriendsWrapper = styled.div`
  display: flex;
  margin-left: 1.5rem;
  flex-direction: row;
`;

const FriendProfile = styled(Image)`
  margin-left: -0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  border-width: 2px;
  border-color: var(--color-white);
`;

function UserFriends({ user }: { user: any }) {
  return (
    <Container>
      <div>
        <FriendCount>{user.friendCount}</FriendCount> {user.friendCount > 1 ? "Friends" : "Friend"}
      </div>
      <FriendsWrapper>
        {user.topFriendsProfilePictureUrlStrings &&
          user.topFriendsProfilePictureUrlStrings.length > 0 &&
          user.topFriendsProfilePictureUrlStrings.map((friendPic, index) => (
            <FriendProfile key={index} alt={"Friend Profile"} src={friendPic} width={16} height={16} />
          ))}
      </FriendsWrapper>
    </Container>
  );
}

export default UserFriends;
