import Router, { useRouter } from "next/router";
import { useCallback } from "react";
import { BunchDetailsUltraLightFragment, BunchDetailsFragment } from "../../gql/types";

type Bunch = BunchDetailsFragment | Partial<BunchDetailsFragment>;

// Hook for use in /pages/b/[slug]
export default function useRouteSlug(): {
  routeSlug: string;
  bunchIsActive: (bunch: Bunch) => boolean;
  getBunchBySlug: (bunches: Bunch[]) => Bunch | null;
} {
  const router = useRouter();
  const { slug } = router.query;

  const bunchIsActive = useCallback(
    (bunch: Bunch) => {
      if (!slug || typeof slug !== "string") return false;

      const isId = checkIfValidUUID(slug);
      return isId ? bunch.bunchId === slug : bunch.slug === slug;
    },
    [slug]
  );

  const getBunchBySlug = useCallback(
    (bunches: Bunch[]) => {
      if (!slug || typeof slug !== "string") return null;

      const isId = checkIfValidUUID(slug);

      const bunch = bunches.find((bunch: Bunch) => {
        return isId ? bunch.bunchId === slug : bunch.slug === slug;
      });

      return bunch || null;
    },
    [slug]
  );

  return { routeSlug: slug as string, bunchIsActive, getBunchBySlug };
}

export function getSlugOrId(bunch: Bunch): string {
  return bunch.slug || bunch.bunchId || "";
}

export function getBunchRoute(bunch: Bunch): string {
  return `/b/${getSlugOrId(bunch)}`;
}

// When routing to a bunch, we will navigate via slug or fall back to bunchId.
export function routeToBunch(bunch: Bunch): Promise<boolean> {
  return Router.push(getBunchRoute(bunch));
}

function checkIfValidUUID(str: string) {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(str);
}
