import { useState } from "react";
import Image, { ImageProps } from "next/image";

export type ImageWithFallbackProps = ImageProps & {
  src: string | null;
  fallbackSrc?: string;
  alt: string;
  width: number;
  height: number;
  className?: string;
  title?: string;
  onClick?: () => void;
};

export default function ImageWithFallback({
  src,
  fallbackSrc = "/img/app_logo.png",
  alt,
  width,
  height,
  className,
  title,
  onClick,
  ...rest
}: ImageWithFallbackProps) {
  const [fallback, setFallback] = useState<string | null>(null);

  if (!src && !fallbackSrc) {
    return null;
  }

  return (
    <Image
      {...rest}
      className={className}
      src={fallback || src || fallbackSrc}
      alt={alt}
      width={width}
      height={height}
      title={title}
      onClick={onClick}
      onError={() => {
        setFallback(fallbackSrc);
      }}
    />
  );
}
