import ExternalMember from "./ExternalMember";
import cloneDeep from "lodash.clonedeep";
import sortBy from "lodash.sortby";
import styled from "styled-components";
import { UserDetailsFragment } from "../../../gql/types";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
`;

const MemberGrid = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
`;

function ExternalMemberList({ members }: { members: UserDetailsFragment[] }) {
  const [displayMembers, setDisplayMembers] = useState<UserDetailsFragment[]>([]);
  const [sortedMembers, setSortedMembers] = useState<UserDetailsFragment[]>([]);

  useEffect(() => {
    let sortedMembers = cloneDeep(members);
    sortedMembers =
      sortedMembers.length > 0 ? sortBy(sortedMembers, ["farcaster", "ens", "unstoppable", "lens"]) : sortedMembers;
    setSortedMembers(sortedMembers);
  }, [members]);

  useEffect(() => {
    setDisplayMembers(sortedMembers);
  }, [sortedMembers, setDisplayMembers]);

  return (
    <Wrapper>
      <MemberGrid>
        {displayMembers &&
          displayMembers.length > 0 &&
          displayMembers.map((user) => <ExternalMember key={user.userId} member={user} />)}
      </MemberGrid>
    </Wrapper>
  );
}

export default ExternalMemberList;
