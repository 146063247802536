import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS, USER_PROFILE_DETAILS } from "../fragments";
import { FetchExternalProfileQuery, FetchExternalProfileQueryVariables } from "../types";

export const FETCH_EXTERNAL_PROFILE: TypedDocumentNode<
  FetchExternalProfileQuery,
  FetchExternalProfileQueryVariables
> = gql`
  ${BUNCH_DETAILS}
  ${USER_PROFILE_DETAILS}
  query fetchExternalProfile($id: String, $term: String) {
    fetchExternalProfile(id: $id, term: $term) {
      id
      type
      object {
        ... on Bunch {
          ...BunchDetails
        }
        ... on UserProfile {
          ...UserProfileDetails
        }
      }
    }
  }
`;
