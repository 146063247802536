import styled from "styled-components";

const Container = styled.div`
  padding-left: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Location = styled.p`
  color: var(--color-gray-1);
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 1.625;
`;

const Dot = styled.span`
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 1.625;
`;

const UrlWrapper = styled.p`
  color: var(--color-primary);
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 1.625;
  cursor: pointer;
`;

const UrlAnchor = styled.a`
  color: var(--color-primary);
  font-size: 1rem;
  line-height: 1.5rem;
  line-height: 1.625;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const UserAbout = styled.span`
  color: var(--color-gray-1);
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

function UserMetadata({ user, displayMode = false }: { user: any; displayMode?: boolean }) {
  const generateAnchorForURL = (url: string) => {
    let linkURL;
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      linkURL = url;
    } else {
      linkURL = `http://${url}`;
    }
    return (
      <UrlAnchor href={linkURL} target="_blank" rel="noreferrer">
        {url}
      </UrlAnchor>
    );
  };

  return (
    <Container>
      <UserAbout>{user.bio}</UserAbout>
      <Wrapper>
        {user.location && <Location>{user.location}</Location>}
        {user.location && user.bioLink && <Dot>•</Dot>}
        {!displayMode && user.bioLink && generateAnchorForURL(user.bioLink)}
        {displayMode && user.bioLink && <UrlWrapper>{user.bioLink}</UrlWrapper>}
      </Wrapper>
    </Container>
  );
}

export default UserMetadata;
