import ImageWithFallback from "../shared/ImageWithFallback";
import styled from "styled-components";
import { useAnalyticsContext } from "../state/AnalyticsProvider";
import { useRouter } from "next/router";

const ExternalSuggestionItemButton = styled.a`
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 4px solid transparent;
  cursor: pointer;
  border-radius: 0.5rem;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--color-gray-1);
  background: var(--color-gray-7);
  border: 2px solid var(--color-gray-1);
  box-shadow: 4px 4px 0px var(--color-gray-1), 0px 0px 12px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 8px 8px 0px var(--color-gray-1), 0px 0px 12px rgba(0, 0, 0, 0.05);
    transform: translate(-8px, -8px);
    transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);
  }

  @media (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
`;

const ExternalSuggestionItemInfoWrapper = styled.div`
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  width: 91.666667%; // 11/12
`;

const ExternalSuggestionItemName = styled.h4`
  overflow: hidden;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const SuggestionImageWithFallback = styled(ImageWithFallback)`
  aspect-ratio: 1/1;
  border-radius: 50%;
`;

function ExternalSuggestionItem({ item }: { item: any }) {
  const router = useRouter();
  const { pushTrackEvent } = useAnalyticsContext();

  return (
    <ExternalSuggestionItemButton
      onClick={() => {
        pushTrackEvent("[External] Clicked Suggestion Item", {
          id: item.userId ?? item.bunchId,
          term: item.username ?? item.slug,
        } as any);

        router.push(`/${item.username ?? item.slug}`);
      }}
    >
      <SuggestionImageWithFallback
        height={40}
        width={40}
        fallbackSrc="/img/default_cover.png"
        src={item.profilePictureUrl ?? item.discoverBackgroundUrl}
        alt={`${item.username ?? item.name} Media`}
      />
      <ExternalSuggestionItemInfoWrapper>
        <ExternalSuggestionItemName>{item.username ?? item.name}</ExternalSuggestionItemName>
      </ExternalSuggestionItemInfoWrapper>
    </ExternalSuggestionItemButton>
  );
}

export default ExternalSuggestionItem;
