import ShareQRCode from "../../shared/ShareQRCode";
import styled from "styled-components";
import { BunchDetailsFragment } from "../../../gql/types";
import { MEDIA_QUERY_SM } from "../../../utilities/constants/mediaQueries";
import { BUNCHES_SHARE_CODE, SHARE_URL } from "../../../utilities/constants/urls";
import { isMobile } from "react-device-detect";
import { useAuthContext } from "../../state/AuthProvider";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const Button = styled.button<{ inverse?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 9999px;
  font-weight: 400;
  font-size: 1rem;
  height: 2.25rem;
  min-width: 10rem;
  margin: 0.5rem 0;
  color: ${({ inverse }) => (inverse ? "var(--color-gray-1)" : "var(--color-background)")};
  background: ${({ inverse }) => (inverse ? "var(--color-background)" : "var(--color-primary)")};
  border: 2px solid var(--color-gray-1);
  box-shadow:
    4px 4px 0px ${({ inverse }) => (inverse ? "#16B900" : "var(--color-gray-1)")},
    0px 0px 12px rgba(0, 0, 0, 0.05);
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);
  transform: translate(0px, 0px);

  &:hover {
    box-shadow:
      6px 6px 0px ${({ inverse }) => (inverse ? "#16B900" : "var(--color-gray-1)")},
      0px 0px 12px rgba(0, 0, 0, 0.05);
    transform: translate(-2px, -2px);
  }

  &:active {
    box-shadow:
      4px 4px 0px #313131,
      0px 0px 0px rgba(0, 0, 0, 0.05);
    transform: translate(0px, 0px);
  }

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 1rem;
    height: 2.25rem;
    margin: 0;
  }
`;

function ExternalJoinButton({ bunch }: { bunch: BunchDetailsFragment }) {
  const router = useRouter();
  const { currentUser } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);
  const [sharerCode, setSharerCode] = useState(router.query?.sharer ?? BUNCHES_SHARE_CODE);

  useEffect(() => {
    setSharerCode(router.query?.sharer ?? BUNCHES_SHARE_CODE);
  }, [router.query]);

  return (
    <>
      <ShareQRCode
        caption={`Scan to join ${bunch.name}`}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shareCode={sharerCode + "/" + bunch.shareCode}
        bunchId={bunch.bunchId}
        userId={currentUser?.userId}
      />
      <Button
        onClick={() => {
          if (isMobile) {
            router.push(SHARE_URL + sharerCode + "/" + bunch.shareCode);
          } else {
            setIsOpen(true);
          }
        }}
      >
        Join {bunch.name}
      </Button>
    </>
  );
}

export default ExternalJoinButton;
