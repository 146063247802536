import styled from "styled-components";
import { UserProfileDetailsFragment } from "../../../gql/types";
import ExternalSocialButton from "./ExternalSocialButton";

const SocialsWrapper = styled.div`
  border-top: 2px solid var(--color-gray-1);
  display: flex;
  width: 100%;
`;

function ExternalUserSocialIcons({ socials }: { socials: UserProfileDetailsFragment["socials"] }) {
  return (
    <SocialsWrapper>
      {socials &&
        socials.length > 0 &&
        socials.map((social, index) => (
          <ExternalSocialButton
            key={`ExternalSocialButton-${index}`}
            network={social.platformName}
            url={`${social.linkUrl}`}
          />
        ))}
    </SocialsWrapper>
  );
}

export default ExternalUserSocialIcons;
