import ExternalFriendButton from "./user/ExternalFriendButton";
import ExternalUserAvatar from "./user/ExternalUserAvatar";
import ExternalUserMetadata from "./user/ExternalUserMetadata";
import ExternalUserProfileCollections from "./user/ExternalUserProfileCollections";
import ExternalUserSocialIcons from "./user/ExternalUserSocialIcons";
import Head from "next/head";
import UserFriends from "./UserFriends";
import Username from "../chat/message/Username";
import styled from "styled-components";
import { MEDIA_QUERY_MD, MEDIA_QUERY_XL } from "../../utilities/constants/mediaQueries";
import { useAnalyticsContext } from "../state/AnalyticsProvider";

const UserInfoWrapper = styled.div`
  border-bottom: 2px solid var(--color-gray-1);
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const UserInfoTop = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: row;
  }
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid var(--color-gray-1);

  @media (min-width: ${MEDIA_QUERY_MD}) {
    border-top: none;
  }
`;

const UserInfoInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
`;

const UsernameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`;

const ProfileUsername = styled(Username)`
  line-height: 3.25rem;
  font-weight: 700;
  font-size: 1.5rem;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 2rem;
  }

  @media (min-width: ${MEDIA_QUERY_XL}) {
    font-size: 3rem;
  }
`;

const ExternalAvatar = styled(ExternalUserAvatar)`
  flex-shrink: 0;
  flex-grow: 0;
`;

function ExternalUserProfile({ userProfile }: { userProfile: any }) {
  const { pushTrackEvent } = useAnalyticsContext();
  const hasSocial = userProfile.socials.length > 0;
  const canonicalUrl = `https://onbunches.com/${userProfile.username}`;
  const separator = "•"
  const titleUsername = userProfile.fullname ? `${userProfile.fullname} ${separator} ` : ``;
  const profileTitle = `${titleUsername}@${userProfile.username} ${separator} Bunches`;

  pushTrackEvent("[External] Viewed User Profile", {
    userId: userProfile.userId,
    username: userProfile.username,
  });

  return (
    <>
      <Head>
        <title>{profileTitle}</title>
        <meta name="description" content={`${userProfile.username} is on Bunches, the best place to chat sports. Check out their profile here.`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="canonical" href={canonicalUrl} />
c
        <link rel="dns-prefetch" href="//merlot.onbunches.com"/>
        <link rel="preconnect" href="//merlot.onbunches.com"/>
        <link rel="dns-prefetch" href="//bunches-profile-pictures.s3.amazonaws.com/"/>
        <link rel="preconnect" href="//bunches-profile-pictures.s3.amazonaws.com/"/>
        <link rel="dns-prefetch" href="//ik.imagekit.io/wrxztkausbq0"/>
        <link rel="preconnect" href="//ik.imagekit.io/wrxztkausbq0"/>

        {/* Open Graph */}
        <meta property="og:title" content={profileTitle} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="profile" />
        <meta property="og:profile:username" content={userProfile.username} />
        <meta property="og:description" content={`${userProfile.username} is on Bunches, the best place to chat sports. Check out their profile here.`} />
        <meta property="og:image" content="https://ik.imagekit.io/wrxztkausbq0/opengraph_image_EYxOnfGVs" />

        {/* Twitter Summary Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onbunches" />
        <meta name="twitter:title" content={profileTitle} />
        <meta name="twitter:description" content={`${userProfile.username} is on Bunches, the best place to chat sports. Check out their profile here.`} />
        <meta name="twitter:image" content="https://ik.imagekit.io/wrxztkausbq0/opengraph_image_EYxOnfGVs" />
        <meta name="twitter:image:alt" content={profileTitle} />

        {/* Branch Link Meta */}
        <meta name="branch:deeplink:bunches_link" content={`bunches://user/${userProfile.userId}`} />

        <script
          dangerouslySetInnerHTML={{
            __html: `
          // load Branch
          (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode".split(" "), 0);
          // init Branch
          branch.init('key_live_ahSqHxz4A4AFEl3RTzSzQojiByeDlvgD');
        `,
          }}
        ></script>
      </Head>
      <UserInfoWrapper>
        <UserInfoTop>
          <ExternalAvatar isReadOnly={true} size={240} user={userProfile} />
          <UserInfo>
            <UserInfoInner>
              <UsernameWrapper>
                <ProfileUsername fontSize={1.875} user={userProfile} showVerified={true} />
                <ExternalFriendButton user={userProfile} />
              </UsernameWrapper>
              <ExternalUserMetadata user={userProfile} />
              {userProfile.friendCount && userProfile.friendCount > 0 ? <UserFriends user={userProfile} /> : <></>}
            </UserInfoInner>
            {hasSocial && <ExternalUserSocialIcons socials={userProfile.socials} />}
          </UserInfo>
        </UserInfoTop>
      </UserInfoWrapper>
      <ExternalUserProfileCollections profile={userProfile} />
    </>
  );
}

export default ExternalUserProfile;
