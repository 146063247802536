import styled from "styled-components";
import ExternalBunchIcon from "./ExternalBunchIcon";
import ExternalJoinButton from "./ExternalJoinButton";
import ExternalCreatorBlock from "./ExternalCreatorBlock";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";
import { BunchDetailsFragment, UserDetailsFragment } from "../../../gql/types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid var(--color-gray-1);
`;

const BunchInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: row;
  }
`;

const ExternalIcon = styled(ExternalBunchIcon)`
  flex-shrink: 0;
`;

const BunchInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const BunchNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
  }
`;

const BunchName = styled.h1`
  display: block;
  font-weight: 900;
  font-size: 2.25rem;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 3rem;
    max-width: 60%;
  }
`;

const BunchMetadata = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
`;

const Description = styled.h2`
  color: var(--color-gray-1);
  font-size: 1.125rem;
  line-height: 1.75rem;
  line-height: 1.625;
`;

function ExternalBunchInfo({ bunch }: { bunch: BunchDetailsFragment }) {
  return (
    <Container>
      <BunchInfoWrapper>
        <ExternalIcon isReadOnly={true} size={240} bunch={bunch} />
        <BunchInfo>
          <BunchMetadata>
            <BunchNameWrapper>
              <BunchName>{bunch.name}</BunchName>
              <ExternalJoinButton bunch={bunch} />
            </BunchNameWrapper>
            <Description>{bunch.description}</Description>
          </BunchMetadata>
          <ExternalCreatorBlock creator={bunch.creator as UserDetailsFragment} />
        </BunchInfo>
      </BunchInfoWrapper>
    </Container>
  );
}

export default ExternalBunchInfo;
