import ExternalUserAvatar from "../user/ExternalUserAvatar";
import Username from "../../chat/message/Username";
import styled from "styled-components";
import { UserDetailsFragment } from "../../../gql/types";

const Container = styled.div`
  align-items: center;
  background: var(--color-background);
  border-top: 2px solid var(--color-gray-1);
  cursor: default;
  display: flex;
  flex-direction: row;
`;

const GutterBackground = styled.div`
  background-color: var(--color-gray-1);
  display: flex;
  flex: 0;
  flex-direction: row;
`;

const CreatorHeader = styled.h1`
  background-color: var(--color-gray-1);
  color: var(--color-background);
  font-weight: 600;
  font-size: 0.85rem;
  padding: 0.5rem;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-orientation: mixed;
`;

const CreatorAvatar = styled(ExternalUserAvatar)`
  aspect-ratio: 1/1;
  border-bottom: none;
  border-right: none;
`;

const InfoWrapper = styled.div`
  flex: 1;
  align-items: center;
  margin-left: 1rem;
`;

const CreatorUsername = styled(Username)`
  margin-right: 0.5rem;
  font-weight: 700;
  font-size: 1.5rem;
  text-overflow: ellipsis;
`;

const AboutWrapper = styled.div`
  color: var(--color-gray-3);
  display: flex;
  display: -webkit-box;
  overflow: hidden;
  font-size: 0.875rem;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

function ExternalCreatorBlock({ creator }: { creator: UserDetailsFragment }) {
  return (
    <Container>
      <GutterBackground>
        <CreatorHeader>Created By</CreatorHeader>
        <CreatorAvatar isReadOnly={true} size={87} user={creator} />
      </GutterBackground>
      <InfoWrapper>
        <CreatorUsername isExternal={true} user={creator} />
        <AboutWrapper>{creator.about}</AboutWrapper>
      </InfoWrapper>
    </Container>
  );
}

export default ExternalCreatorBlock;
