import { useState } from "react";
import styled from "styled-components";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";
import ExternalPill from "../ExternalPill";
import ExternalProfileCollectionEmptyState from "./ExternalProfileCollectionEmptyState";
import ExternalProfileCollectionGrid from "./ExternalProfileCollectionGrid";
import ExternalProfileCollectionList from "./ExternalProfileCollectionList";

const ExternalUserProfileCollectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  transform: 0px;
`;

const ExternalUserProfileCollectionsNav = styled.div`
  display: flex;
  margin-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: row;
  width: 100%;
  cursor: pointer;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    margin-bottom: 0.5rem;
    padding-right: 2rem;
  }
`;

const ExternalUserProfileCollectionsContent = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  width: 100%;
`;

function ExternalUserProfileCollections({ profile }) {
  const [viewIndex, setViewIndex] = useState(0);

  return (
    <ExternalUserProfileCollectionsWrapper>
      <ExternalUserProfileCollectionsNav>
        {profile &&
          profile.collections &&
          profile.collections.length > 1 &&
          profile.collections.map((collection, index) => (
            <ExternalPill
              key={`ExternalUserProfileCollections-Pill-div-${index}`}
              caption={collection.name}
              iconUrl={collection.pillIconUrl ?? false}
              isActive={viewIndex === index}
              action={() => {
                setViewIndex(index);
              }}
              spacing={4}
            />
          ))}
      </ExternalUserProfileCollectionsNav>
      {profile && profile.collections && profile.collections.length > 0 && profile.collections[viewIndex] && (
        <ExternalUserProfileCollectionsContent>
          {!profile.collections[viewIndex].emptyState &&
            profile.collections[viewIndex].items.length > 0 &&
            (profile.collections[viewIndex].displayType === "list" ? (
              <ExternalProfileCollectionList items={profile.collections[viewIndex].items} />
            ) : (
              <ExternalProfileCollectionGrid items={profile.collections[viewIndex].items} />
            ))}
          {profile.collections[viewIndex].emptyState && (
            <ExternalProfileCollectionEmptyState emptyState={profile.collections[viewIndex].emptyState} />
          )}
        </ExternalUserProfileCollectionsContent>
      )}
    </ExternalUserProfileCollectionsWrapper>
  );
}

export default ExternalUserProfileCollections;
