import UserAvatar from "../../../components/chat/message/UserAvatar";
import Username from "../../shared/user/Username";
import styled from "styled-components";
import { MEDIA_QUERY_MD, MEDIA_QUERY_XL } from "../../../utilities/constants/mediaQueries";
import { UserDetailsFragment } from "../../../gql/types";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import { useRouter } from "next/router";

const Wrapper = styled.div`
  display: flex;
  padding: 0.5rem;
  margin-right: 0.5rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 0.75rem;
  border-width: 2px;
  border-color: transparent;
  cursor: pointer;
  color: var(--color-gray-1);
  background: var(--color-gray-7);
  border: 2px solid var(--color-gray-1);
  box-shadow: 4px 4px 0px var(--color-gray-1), 0px 0px 12px rgba(0, 0, 0, 0.05);
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:hover {
    box-shadow: 8px 8px 0px var(--color-gray-1), 0px 0px 12px rgba(0, 0, 0, 0.05);
    transform: translate(-4px, -4px);
  }

  &:active {
    box-shadow: 0px 0px 0px #313131, 0px 0px 0px rgba(0, 0, 0, 0.05);
    transform: translate(4px, 4px);
  }

  @media (min-width: ${MEDIA_QUERY_MD}) {
    width: calc(33% - 20px);
  }

  @media (min-width: ${MEDIA_QUERY_XL}) {
    width: calc(25% - 20px);
  }
`;

const ExternalAvatar = styled(({ ...props }) => <UserAvatar {...props} />)`
  display: block;
  flex: 0 0 auto;
  margin-bottom: 0.125rem;
`;

const UsernameWrapper = styled.div`
  margin-left: 0.5rem;
`;

const ExternalUsername = styled(({ ...props }) => <Username {...props} />)`
  overflow: hidden;
  margin-top: 0.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: normal;
  overflow-wrap: break-word;

  > span {
    color: var(--color-gray-2) !important;
  }
`;

function ExternalMember({ member }: { member: UserDetailsFragment }) {
  const router = useRouter();
  const { pushTrackEvent } = useAnalyticsContext();

  return (
    <Wrapper
      onClick={() => {
        pushTrackEvent("[External] Clicked Member Profile", {
          userId: member.userId,
          username: member.username,
        });

        router.push(`@${member.username}`);
      }}
    >
      <ExternalAvatar isReadOnly={true} size={64} user={member} />
      <UsernameWrapper>
        <ExternalUsername fontSize={1.125} user={member} isExternal={true} />
      </UsernameWrapper>
    </Wrapper>
  );
}

export default ExternalMember;
