import ExternalProfileCollectionGridItem from "./ExternalProfileCollectionGridItem";
import styled from "styled-components";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";

const ExternalProfileCollectionGridWrapper = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 2rem;
  width: 100%;
  height: 100%;
`;

const ExternalProfileCollectionGridItemsWrapper = styled.div`
  display: grid;
  width: 100%;
  min-height: 100dvh;
  padding-bottom: 4rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-top: 0.5rem;
  }
`;

function ExternalProfileCollectionGrid({ items }: { items: any[] }) {
  return (
    <ExternalProfileCollectionGridWrapper>
      <ExternalProfileCollectionGridItemsWrapper>
        {items && items.map((item, index) => <ExternalProfileCollectionGridItem key={index} item={item} />)}
      </ExternalProfileCollectionGridItemsWrapper>
    </ExternalProfileCollectionGridWrapper>
  );
}

export default ExternalProfileCollectionGrid;
