import styled from "styled-components";
import MediaItem from "../../shared/MediaItem";

const ExternalProfileCollectionGridItemAnchor = styled.a`
  display: flex;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  flex-direction: column;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  &:hover {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    transform: scale(1.02);
  }
`;

const ExternalProfileCollectionGridItemName = styled.h4`
  color: var(--color-gray-1);
  overflow: hidden;
  margin-left: 0.25rem;
  padding: 1rem;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

function ExternalProfileCollectionGridItem({ item }: { item: any }) {
  return (
    <ExternalProfileCollectionGridItemAnchor
      target={item.targetUrl.includes("localhost") ? "" : "_blank"}
      rel="noreferrer"
      href={item.targetUrl}
    >
      <MediaItem isSquare={false} fallback="/img/default_cover.png" itemUrl={item.imageUrl} alt={item.name} />
      <ExternalProfileCollectionGridItemName>{item.name}</ExternalProfileCollectionGridItemName>
    </ExternalProfileCollectionGridItemAnchor>
  );
}

export default ExternalProfileCollectionGridItem;
