import Image from "next/image";
import styled from "styled-components";
import Heading from "../../shared/Heading";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

function ExternalHighlightEmptyState() {
  return (
    <Container>
      <Heading fontSize="2rem">Nothing pinned yet!</Heading>
      <Image src="/illustrations/noBunches.png" alt="No Bunches" width={128} height={128} />
      <Heading>As content is pinned in the Bunch, it&apos;ll appear here!</Heading>
    </Container>
  );
}

export default ExternalHighlightEmptyState;
