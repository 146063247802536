import ButtonPrimary from "../../shared/ButtonPrimary";
import ImageWithFallback from "../../shared/ImageWithFallback";
import styled from "styled-components";
import { getWebURLFromBunchesURL } from "../../../utilities";
import { useRouter } from "next/router";

const ExternalProfileCollectionEmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100%;
`;

const EmptyStateTitle = styled.div`
  color: var(--color-gray-2);
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
`;

const EmptyStateButton = styled(ButtonPrimary)`
  margin-top: 1rem;
  width: 18rem;
`;

function ExternalProfileCollectionEmptyState({ emptyState }: { emptyState: any }) {
  const router = useRouter();
  return (
    <ExternalProfileCollectionEmptyStateWrapper>
      <ImageWithFallback src={emptyState.iconUrl} width={48} height={48} alt={emptyState.title} />
      <EmptyStateTitle>{emptyState.title}</EmptyStateTitle>
      {emptyState.targetUrl && (
        <EmptyStateButton
          action={() => {
            let actionUrl = getWebURLFromBunchesURL(emptyState.targetUrl);
            router.push(actionUrl);
          }}
          caption={emptyState.buttonTitle}
          type={"button"}
        />
      )}
    </ExternalProfileCollectionEmptyStateWrapper>
  );
}

export default ExternalProfileCollectionEmptyState;
