import { TypedDocumentNode } from "@apollo/client";
import gql from "graphql-tag";
import { BUNCH_DETAILS, USER_PROFILE_DETAILS } from "../fragments";
import { FetchExternalSuggestionsQuery, FetchExternalSuggestionsQueryVariables } from "../types";

export const FETCH_EXTERNAL_SUGGESTIONS: TypedDocumentNode<
  FetchExternalSuggestionsQuery,
  FetchExternalSuggestionsQueryVariables
> = gql`
  ${BUNCH_DETAILS}
  ${USER_PROFILE_DETAILS}
  query fetchExternalSuggestions($term: String) {
    fetchExternalSuggestions(term: $term) {
      ... on Bunch {
        ...BunchDetails
      }
      ... on UserProfile {
        ...UserProfileDetails
      }
    }
  }
`;
