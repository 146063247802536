import ExternalSuggestionItem from "./ExternalSuggestionItem";
import styled from "styled-components";
import { BunchDetailsFragment } from "../../gql/types";
import { FETCH_EXTERNAL_SUGGESTIONS } from "../../gql/external/fetchExternalSuggestions";
import { useAnalyticsContext } from "../state/AnalyticsProvider";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

const Header = styled.h1`
  color: var(--color-gray-1);
  cursor: default;
  font-size: 2rem;
  font-weight: 600;
`;

const Term = styled.span`
  color: var(--color-gray-2);
  font-weight: 800;
  text-decoration: #ff0088 underline dotted;
`;

const ExternalSuggestionsWrapper = styled.div`
  display: grid;
  width: 80%;
  padding-bottom: 2rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.25rem;
  @media (min-width: 768px) {
    padding-top: 2rem;
  }
`;

function NoExternalProfile({ term }: { term: string }) {
  const { pushTrackEvent } = useAnalyticsContext();
  const [suggestions, setSuggestions] = useState<BunchDetailsFragment[]>([]);
  const getSuggestions = useQuery(FETCH_EXTERNAL_SUGGESTIONS, {
    variables: { term: term },
  });

  pushTrackEvent("[External] No External Profile", { term: term } as any);

  useEffect(() => {
    if (getSuggestions.loading) return;
    if (!getSuggestions || !getSuggestions.data || !getSuggestions.data.fetchExternalSuggestions) return;
    pushTrackEvent("[External] Viewed External Suggestions", {
      term: term,
      suggestionCount: getSuggestions.data.fetchExternalSuggestions.length,
    } as any);
    setSuggestions(getSuggestions.data.fetchExternalSuggestions as BunchDetailsFragment[]);
  }, [getSuggestions, pushTrackEvent, term]);

  return (
    <Container>
      <Header>
        Oh no! We couldn&apos;t find anything for <Term>{term}</Term>
      </Header>
      {suggestions?.length > 0 && (
        <>
          <Header>...but we did find these {suggestions.length} things:</Header>
          <ExternalSuggestionsWrapper>
            {suggestions.map((item, index) => (
              <ExternalSuggestionItem key={index} item={item} />
            ))}
          </ExternalSuggestionsWrapper>
        </>
      )}
    </Container>
  );
}

export default NoExternalProfile;
