import ImageWithFallback from "./ImageWithFallback";
import styled from "styled-components";

const Wrapper = styled.div<{ minHeight: number; minWidth: number; isSquare: boolean }>`
  aspect-ratio: ${({ isSquare }) => (isSquare ? "1/1" : "1")};
  border-radius: ${({ isSquare }) => (isSquare ? "0.5rem" : "0px")};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: block;
  height: ${({ minHeight }) => (minHeight ? minHeight + "px" : "auto")};
  object-fit: cover;
  flex-shrink: 0;
  object-position: center;
  position: relative;
  overflow: hidden;
  width: ${({ minWidth }) => (minWidth ? minWidth + "px" : "auto")};
`;

const MediaImage = styled(ImageWithFallback)<{ isSquare: boolean }>`
  aspect-ratio: ${({ isSquare }) => (isSquare ? "1/1" : "1")};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: block;
  object-fit: cover;
  object-position: center;
  position: relative;
`;

function MediaItem({
  itemUrl,
  fallback,
  isSquare,
  alt,
  width,
  height,
  minHeight,
  minWidth,
  priority,
}: {
  itemUrl: string;
  fallback: string;
  isSquare: boolean;
  alt: string;
  width: number;
  height: number;
  minHeight: number;
  minWidth: number;
  priority?: boolean;
}) {
  if (!itemUrl)
    return (
      <Wrapper isSquare={isSquare} minHeight={minHeight} minWidth={minWidth}>
        <MediaImage
          isSquare={isSquare}
          sizes="100%"
          fill={!width && !height}
          width={width}
          height={height}
          src={fallback}
          fallbackSrc={fallback}
          alt={alt ?? "Media Item Rendered"}
          priority={priority ?? false}
        />
      </Wrapper>
    );
  if (itemUrl === "") return <span>Empty</span>;
  return (
    <Wrapper isSquare={isSquare} minHeight={minHeight} minWidth={minWidth}>
      <MediaImage
        isSquare={isSquare}
        sizes="100%"
        fill={!width && !height}
        width={width}
        height={height}
        src={itemUrl}
        fallbackSrc={fallback}
        alt={alt ?? "Media Item Rendered"}
        priority={priority ?? false}
      />
    </Wrapper>
  );
}

export default MediaItem;
