import FocusLock from "react-focus-lock";
import PhoneInput from "react-phone-input-2";
import PopupModal from "../shared/PopupModal";
import styled from "styled-components";
import { Dispatch, SetStateAction, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { SEND_SHARE_SMS } from "../../gql/share/sendShareSms";
import { SHARE_URL } from "../../utilities/constants/urls";
import { displayToast, displayToastError } from "./toasts";
import { handleCaughtError } from "../../utilities";
import { useHotkeys } from "react-hotkeys-hook";
import { useMutation } from "@apollo/client";

const Inner = styled.div`
  padding: 0 6rem;
`;

const CaptionContainer = styled.div`
  margin: 0 auto 2rem;
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Separator = styled.hr`
  margin: 2rem 0 1rem;
  width: 80%;
  border: 1px solid var(--color-gray-3);
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 91.6666667%;
`;

const CTALabel = styled.label`
  display: block;
  margin-bottom: 1rem;
  color: var(--color-gray-1);
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
`;

const CTAWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
`;

const CTAInner = styled.div`
  position: relative;
  margin-right: 0.5rem;
  width: 100%;
`;

const CTAIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 0.5rem;
  align-items: center;
  pointer-events: none;
`;

const CTASVG = styled.svg`
  width: 1.25rem;
  height: 1rem;
`;

const CTAInput = styled(PhoneInput)`
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 2rem;
  background-color: var(--color-gray-7);
  color: var(--color-gray-1);
  width: 100%;
  border-radius: 0.5rem;
  border-width: 1px;
  border-color: var(--color-gray-3);

  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }

  &:hover {
    outline: none;
    border-color: var(--color-primary);
  }
  .form-control {
    padding-right: 1rem;
    height: auto;
    width: 100%;
    font-size: 1rem;
    line-height: 1;

    &:focus {
      outline: 0;
    }
  }

  .flag-dropdown {
    display: none;
  }
`;

const CTASendButton = styled.button`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #ffffff;
  text-align: center;
  border-radius: 0.5rem;
  border-width: 1px;
  cursor: pointer;
  background: var(--color-primary);

  &:disabled {
    background: var(--color-gray-4);
  }
`;

function ShareQRCode({
  bunchId,
  caption,
  isOpen,
  setIsOpen,
  shareCode,
  userId,
}: {
  bunchId?: string;
  caption: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  shareCode: string;
  userId?: string;
}) {
  const [sendShareSms] = useMutation(SEND_SHARE_SMS);
  const [phone, setPhone] = useState<string | null>(null);

  useHotkeys("Esc", () => setIsOpen(false));

  return (
    <PopupModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <FocusLock autoFocus={false}>
        <Inner>
          <CaptionContainer>{caption}</CaptionContainer>
          <Container>
            <QRCode
              size={300}
              logoWidth={40}
              logoHeight={40}
              qrStyle="squares"
              quietZone={0}
              logoImage="/img/app_logo.png"
              removeQrCodeBehindLogo={true}
              value={`${SHARE_URL}${shareCode}`}
            />
            <Separator />
            <FormWrapper>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();

                  if (!phone?.trim()) {
                    displayToastError("Provide a phone number first");
                    return;
                  }

                  try {
                    await sendShareSms({ variables: { phone, bunchId, userId } });
                    displayToast("Invite link sent!", { type: "success" });
                    setPhone(null);
                  } catch (err) {
                    handleCaughtError(err);
                  }
                }}
              >
                <CTALabel htmlFor="phone">Text an invite link</CTALabel>
                <CTAWrapper>
                  <CTAInner>
                    <CTAIconWrapper>
                      <CTASVG fill="#828282" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path>
                      </CTASVG>
                    </CTAIconWrapper>
                    <CTAInput
                      country="us"
                      preferredCountries={["us"]}
                      value={phone}
                      specialLabel=""
                      countryCodeEditable={false}
                      onChange={(value: string) => {
                        setPhone(value);
                      }}
                      placeholder={"+1 (201) 555-0123"}
                    />
                  </CTAInner>
                  <CTASendButton type="submit" disabled={!phone?.trim()}>
                    Send
                  </CTASendButton>
                </CTAWrapper>
              </form>
            </FormWrapper>
          </Container>
        </Inner>
      </FocusLock>
    </PopupModal>
  );
}

export default ShareQRCode;
