import Link from "next/link";
import styled from "styled-components";

const FooterWrapper = styled.div`
  border-top: 2px solid var(--color-gray-1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 0.75rem;
  margin-top: 2rem;
  padding: 1rem;
`;

const FooterSpan = styled.span``;

function ExternalFooter() {
  return (
    <FooterWrapper>
      <FooterSpan>
        ©2023{" "}
        <Link href="/" title="Bunches">
          Bunches
        </Link>
      </FooterSpan>
    </FooterWrapper>
  );
}

export default ExternalFooter;
