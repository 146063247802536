// Social assets
export const SOCIAL_ASSETS = {
  ens: { background: "#647cf6", image: "/icons/ethereum.png", show: false, text: "#FFFFFF" },
  farcaster: { background: "#7f5bc1", image: "/icons/farcaster.png", show: false, text: "#FFFFFF" },
  instagram: { background: "#c32aa3", image: "/icons/instagram.png", show: true, text: "#FFFFFF" },
  spotify: { background: "#1ed760", image: "/icons/spotify.png", show: true, text: "#FFFFFF" },
  tiktok: { background: "#010101", image: "/icons/tiktok.png", show: true, text: "#FFFFFF" },
  twitter: { background: "#1da1f2", image: "/icons/twitter.png", show: true, text: "#FFFFFF" },
  youtube: { background: "#ff0000", image: "/icons/youtube.png", show: true, text: "#FFFFFF" },
};
