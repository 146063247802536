import ExternalHighlightEmptyState from "./ExternalHighlightEmptyState";
import ExternalHighlightItem from "./ExternalHighlightItem";
import orderBy from "lodash.orderby";
import styled from "styled-components";
import { BunchDetailsFragment, MessageDetailsFragment } from "../../../gql/types";
import { FETCH_BUNCH_HIGHLIGHTS } from "../../../gql/bunch/fetchBunchHighlights";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  width: 100%;
  height: 100%;
`;

export default function HighlightList({
  bunch,
  highlights = [],
  isExternal = false,
}: {
  bunch: BunchDetailsFragment;
  highlights?: MessageDetailsFragment[];
  isExternal?: boolean;
}) {
  const [highlightList, setHighlightList] = useState<MessageDetailsFragment[]>(highlights);
  const { data } = useQuery(FETCH_BUNCH_HIGHLIGHTS, { variables: { bunchId: bunch.bunchId, userId: bunch.creatorId } });

  useEffect(() => {
    if (highlights) return;
    if (
      !data ||
      !data.fetchBunchHighlights ||
      !data.fetchBunchHighlights.messages ||
      data.fetchBunchHighlights.messages.length <= 0
    )
      return;
    const ordered = orderBy(data.fetchBunchHighlights.messages, ["insertedAt"], ["desc"]);

    setHighlightList(ordered);
  }, [data, highlights]);

  return (
    <Container>
      {highlightList && highlightList.length === 0 && <ExternalHighlightEmptyState />}
      {highlightList &&
        highlightList.length > 0 &&
        highlightList.map((highlight, index) => (
          <ExternalHighlightItem
            key={`ExternalHighlightItem-${index}`}
            isExternal={isExternal}
            message={highlight as any}
          />
        ))}
    </Container>
  );
}
