import ImageWithFallback from "../../shared/ImageWithFallback";
import styled from "styled-components";
import { BunchDetailsFragment } from "../../../gql/types";
import { MEDIA_QUERY_MD } from "../../../utilities/constants/mediaQueries";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";
import { useRouter } from "next/router";

const Button = styled.button<{ size: number }>`
  border-right: 2px solid var(--color-gray-1);
  position: relative;
  flex: 1 0 auto;
`;

const Avatar = styled(ImageWithFallback)<{ size: number }>`
  object-fit: cover;
  width: 100%;
  height: ${({ size }) => (size ? size + "px" : "120px")};

  @media (min-width: ${MEDIA_QUERY_MD}) {
    height: 100%;
    margin-bottom: auto;
    width: ${({ size }) => (size ? size + "px" : "120px")};
  }
`;

export default function ExternalBunchIcon({
  bunch,
  isReadOnly = false,
  action,
  size = 40,
  className,
}: {
  bunch: BunchDetailsFragment;
  isReadOnly?: boolean;
  action?: () => void;
  size?: number;
  className?: string;
}) {
  const router = useRouter();
  const { pushTrackEvent } = useAnalyticsContext();

  if (!bunch) {
    return null;
  }

  return (
    <Button
      size={size}
      className={className}
      onClick={() => {
        if (isReadOnly) return;

        pushTrackEvent("[External] Clicked Bunch Icon", {
          bunchId: bunch.bunchId,
          name: bunch.name,
          slug: bunch.slug,
        } as any);

        action?.();

        router.push(`$${bunch.slug}`);
      }}
    >
      <Avatar
        priority
        src={bunch?.discoverBackgroundUrl ?? bunch?.discoverIconUrl}
        fallbackSrc="/img/default_profile.png"
        alt={bunch?.name + "'s Bunch Icon"}
        size={size}
        height={size}
        width={size}
        title={bunch?.name}
      />
    </Button>
  );
}
