import styled from "styled-components";
import ExternalProfileCollectionListItem from "./ExternalProfileCollectionListItem";

const ExternalProfileCollectionListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 2rem;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

function ExternalProfileCollectionList({ items }) {
  return (
    <>
      <ExternalProfileCollectionListWrapper>
        {items &&
          items.length > 0 &&
          items.map((item, index) => <ExternalProfileCollectionListItem key={index} item={item} />)}
      </ExternalProfileCollectionListWrapper>
    </>
  );
}

export default ExternalProfileCollectionList;
