import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { MEDIA_QUERY_SM, MEDIA_QUERY_XS } from "../../utilities/constants/mediaQueries";

const StyledButton = styled.button<{ subPill: boolean; spacing: number; isActive: boolean }>`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 100%;
  font-family: var(--font-family-headline);
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid var(--color-gray-1);
  margin-right: 1rem;
  padding: 1rem;
  background: ${({ isActive }) => (isActive ? "var(--color-primary)" : "var(--color-background)")};
  color: ${({ isActive }) => (isActive ? "var(--color-background)" : "var(--color-gray-1)")};

  @media (min-width: ${MEDIA_QUERY_XS}) {
    width: 10rem;
    font-size: 1rem;
  }

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 1.25rem;
    min-width: 14rem;
    height: 100%;
  }

  &:hover {
    background: var(--color-primary);
    color: var(--color-background);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  display: inline-block;
  height: 1rem;
  width: auto;
  margin-right: 0.5rem;
  margin-left: -0.125rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

function ExternalPill({
  action,
  caption = "",
  icon,
  isActive,
  spacing,
  subPill = false,
  ...props
}: {
  action: () => void;
  caption: string;
  icon: IconProp;
  isActive: boolean;
  spacing: number;
  subPill?: boolean;
}) {
  return (
    <StyledButton
      isActive={isActive}
      spacing={spacing}
      subPill={subPill}
      onClick={() => {
        action();
      }}
      {...props}
    >
      {icon && <Icon icon={icon} />}
      {caption}
    </StyledButton>
  );
}

export default ExternalPill;
