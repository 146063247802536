import ExternalFooter from "../ExternalFooter";
import styled from "styled-components";
import { ReactNode } from "react";
import { StickyHeader } from "../../home/StickyHeader";
import { useRouter } from "next/router";
import { isAndroid, isIOS } from "react-device-detect";
import { DOWNLOAD_URL_ANDROID, DOWNLOAD_URL_IOS } from "../../../utilities/constants/urls";

const OuterContainer = styled.div`
  background: var(--color-home-background);
  border-top: 2px solid var(--color-gray-1);
  border-bottom: 2px solid var(--color-gray-1);
`;

const InnerContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vw;
  width: 100%;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;

  @media (min-width: 1280px) {
    border-left: 2px solid var(--color-gray-1);
    border-right: 2px solid var(--color-gray-1);
  }
`;

const Main = styled.main`
  flex: 1 0 auto;
  width: 100%;
`;

export default function ExternalLayout({ children }: { children: ReactNode }) {
  const router = useRouter();

  return (
    <OuterContainer>
      <StickyHeader
        onCtaClick={() => {
          if (isIOS) {
            router.push(DOWNLOAD_URL_IOS);
          } else if (isAndroid) {
            router.push(DOWNLOAD_URL_ANDROID);
          } else {
            router.push("/?qr=1");
          }
        }}
      />
      <InnerContainer>
        <Main>{children}</Main>
        <ExternalFooter />
      </InnerContainer>
    </OuterContainer>
  );
}
