import ExternalBunchInfo from "./bunch/ExternalBunchInfo";
import ExternalHighlightList from "./bunch/ExternalHighlightList";
import ExternalMemberList from "./bunch/ExternalMemberList";
import ExternalPill from "./ExternalPill";
import Head from "next/head";
import styled from "styled-components";
import { BunchDetailsFragment, UserDetailsFragment } from "../../gql/types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faComment, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { useAnalyticsContext } from "../state/AnalyticsProvider";
import { useState } from "react";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
`;

const TabBar = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
`;

const ContentWrapper = styled.div`
  overflow-y: auto;
  padding: 1rem;
  width: 100%;
`;

function ExternalBunchProfile({ bunch }: { bunch: BunchDetailsFragment }) {
  const { pushTrackEvent } = useAnalyticsContext();
  const [viewIndex, setViewIndex] = useState(0);

  pushTrackEvent("[External] Viewed Bunch Profile", {
    bunchId: bunch.bunchId,
    name: bunch.name,
    creatorId: bunch.creatorId,
  });

  return (
    <>
      <Head>
        <title>Bunches • {bunch.name}</title>
        <meta name="description" content={bunch.description ?? "Bunches"} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />

        {/* Open Graph */}
        <meta property="og:title" content={`Join ${bunch.name} and chat sports on Bunches`} />
        <meta property="og:url" content={`https://onbunches.com/${bunch.slug}`} />
        <meta property="og:image" content="https://ik.imagekit.io/wrxztkausbq0/opengraph_image_EYxOnfGVs" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={`Bunches are group chats about sports. ${bunch.name} is a bunch about ${bunch.description}`} />

        {/* Twitter Summary Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@onbunches" />
        <meta name="twitter:title" content={`Join ${bunch.name} and chat sports on Bunches!`} />
        <meta name="twitter:description" content={`Bunches are group chats about sports. ${bunch.name} is a bunch about ${bunch.description}`} />
        <meta name="twitter:image" content="https://ik.imagekit.io/wrxztkausbq0/opengraph_image_EYxOnfGVs" />
        <meta name="twitter:image:alt" content={`Join ${bunch.name} and chat sports on Bunches!`} />

        {/* Branch Link Meta */}
        <meta name="branch:deeplink:bunches_link" content={`bunches://bunch/${bunch.bunchId}`} />

        <script
          dangerouslySetInnerHTML={{
            __html: `
          // load Branch
          (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener banner closeBanner closeJourney data deepview deepviewCta first init link logout removeListener setBranchViewData setIdentity track trackCommerceEvent logEvent disableTracking getBrowserFingerprintId crossPlatformIds lastAttributedTouchData setAPIResponseCallback qrCode".split(" "), 0);
          // init Branch
          branch.init('key_live_ahSqHxz4A4AFEl3RTzSzQojiByeDlvgD');
        `,
          }}
        ></script>
      </Head>
      <ExternalBunchInfo bunch={bunch} />
      <Content>
        <TabBar>
          <ExternalPill
            caption={`Members (${bunch.memberCount})`}
            icon={faComment as IconProp}
            isActive={viewIndex === 0}
            action={() => {
              setViewIndex(0);
            }}
            spacing={2}
          />
          <ExternalPill
            caption="Pinned"
            icon={faMapPin as IconProp}
            isActive={viewIndex === 1}
            action={() => {
              setViewIndex(1);
            }}
            spacing={2}
          />
        </TabBar>
        <ContentWrapper>
          {viewIndex === 0 && <ExternalMemberList members={bunch.members as UserDetailsFragment[]} />}
          {viewIndex === 1 && <ExternalHighlightList bunch={bunch} />}
        </ContentWrapper>
      </Content>
    </>
  );
}

export default ExternalBunchProfile;
